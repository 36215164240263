import React from 'react';
import _ from 'lodash';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import PaginationModel from '@uz/unitz-models/PaginationModel';
import B2BCourseModel from '@uz/unitz-models/B2BCourseModel';
import moment from 'moment';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            paginationModel: hook((ctx) => {
              const ref = React.useRef({});
              const userId = ctx.apply('authModel.getUserId');
              const account_id = ctx.apply('accountModel.getAccountId');
              const pagingModel = React.useMemo(() => {
                const dataQuery = `
                  b2b_account_subscription_transaction (
                    where: $account_subscription_transaction_bool_exp
                    order_by: {created_at: desc_nulls_last}
                  ){
                    id
                    gw_transaction_id
                    amount
                    currency_iso_code
                    created_at
                    credit_card
                    paypal_account
                  }
                `;
                const pagingModel = PaginationModel.fromConfig({
                  Model: B2BCourseModel,
                  dataQuery,
                  aggQuery: `
                    b2b_account_subscription_transaction_aggregate(where: $account_subscription_transaction_bool_exp) {
                      aggregate {
                        count
                      }
                    }
                  `,
                  pageSize: 1000,
                  varsList: '$account_subscription_transaction_bool_exp: b2b_account_subscription_transaction_bool_exp',
                  getVars: async () => {
                    return {
                      account_subscription_transaction_bool_exp: { account_id: { _eq: account_id } },
                    };
                  },
                });
                return pagingModel;
              }, []);

              pagingModel.hookState();

              React.useEffect(() => {
                pagingModel.onLoadPageHandler(0);
              }, [pagingModel]);

              const pagination = {
                items: pagingModel.useState('items'),
                page: pagingModel.useState('page'),
                total: pagingModel.useState('total'),
                isLoading: pagingModel.useState('isLoading'),
                pageSize: pagingModel.useState('pageSize'),
                hasPreviousPage: pagingModel.useState('hasPreviousPage'),
                hasNextPage: pagingModel.useState('hasNextPage'),
                hasLoadMore: pagingModel.useState('hasLoadMore'),
                hasFilterArgs: () => !!_.compact(_.values(ref.current.getFilterArgs())).length,
                onRefresh: (...args) => pagingModel.onRefresh(...args),
                onPreviousPage: (...args) => pagingModel.onPreviousPageHandler(...args),
                onNextPage: (...args) => pagingModel.onNextPageHandler(...args),
                onLoadPage: (...args) => pagingModel.onLoadPageHandler(...args),
              };

              ctx.apply('REF.setRef', 'CoursePagingModel', pagination);
              return pagination;
            }),
            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-center text-xs">
                      {ctx.apply('i18n.rt', 'ID')}
                    </div>
                  ),
                  width: 50,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        {record?.gw_transaction_id}
                      </div>
                    );
                  },
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.rt', 'Date')}
                    </div>
                  ),
                  width: 120,
                  render: (val, record) => {
                    return (
                      <div className="text-center">{moment(record?.created_at).format('YYYY-MM-DD')}</div>
                    );
                  },
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.rt', 'Payment method')}
                    </div>
                  ),
                  width: 120,
                  render: (val, record) => {
                    const cardType = _.get(record, 'credit_card.cardType');
                    if (cardType) {
                      const last4 = _.get(record, 'credit_card.last4');
                      return (
                        <div className="text-center">{ctx.apply('i18n.rt', `${cardType} ending in ${last4}`)}</div>
                      );
                    }
                    const payerEmail = _.get(record, 'paypal_account.payerEmail');
                    if (payerEmail) {
                      return (
                        <div className="text-center">{ctx.apply('i18n.rt', `Paypal: ${payerEmail}`)}</div>
                      );
                    }
                    return '--';
                  },
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.rt', 'Amount')}
                    </div>
                  ),
                  width: 120,
                  render: (val, record) => {
                    return (
                      <div className="text-center">{ctx.apply('i18n.l', 'currency', _.get(record, 'amount'))}</div>
                    );
                  },
                },
                {
                  title: (
                    <div className="text-sub text-center font-semibold text-xs">
                      {ctx.apply('i18n.rt', 'Receipt')}
                    </div>
                  ),
                  width: 20,
                  render: (val, record) => {
                    return null;
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourses'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('paginationModel');

              const allColumns = ctx.get('allColumns');

              const columns = ctx.get('allColumns');
              const selectedColumns = _.map(columns, 'key');

              return {
                pagination: {
                  pageSize: data.pageSize,
                  total: data.total,
                  showLessItems: true,
                  onChange: (page) => {
                    data.onLoadPage(page - 1);
                  },
                },
                dataSource: data.items,
                columns,
                allColumns,
                selectedColumns,
              };
            }),

          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
});

export default bindData;
