import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetB2BCourse from 'unitz-gql/b2b_course/GetB2BCourse.gql';

graphme.GqlBuilder.loadDocument({
  GetB2BCourse,
});

class B2BCourseModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Course',
    schema: {
      id: String,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetB2BCourse',

    GQL_ACTIONS: {
      FIND: 'b2b_course',
      GET: 'b2b_course_by_pk',
      INSERT: 'insert_b2b_course_one',
      UPDATE: 'update_b2b_course_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }
}

graphme.model({ B2BCourseModel });

export default B2BCourseModel;
