import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import _ from 'lodash';
import { UnAuthRedirect } from '@uz/unitz-components-web/AppRedirect';
import CustomTable from '@uz/unitz-components-web/CustomTable';


const Index = () => {
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4 mx-auto max-w-screen-lg" style={{ maxWidth: 1024 }}>
          <div className="text-brandb500 font-semibold text-2xl mb-6">
            {ctx.apply('i18n.rt', 'Payment history')}
          </div>
          <CustomTable
            columns={ctx.get('tableColumn.columns')}
            dataSource={ctx.get('tableColumn.dataSource', [])}
            rowKey={(rec) => _.get(rec, 'id')}
            loading={ctx.get('paginationModel.isLoading')}
            pagination={ctx.get('tableColumn.pagination')}
            onChange={ctx.get('paginationModel.onChange')}
            size="middle"
          />
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default Index;
