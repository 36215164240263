import React from 'react';
import gstyles from '@vl/gstyles';
import { Table } from 'antd';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import styled from 'styled-components';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';

const StyledTable = styled(Table)`
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        border-radius: 8px;
        .ant-table-container {
          .ant-table-content {
            table {
              width: 100% !important;
            }
            table > thead > tr:first-child th:last-child {
              border-top-right-radius: 8px;
            }
            table > thead > tr:first-child th:first-child {
              border-top-left-radius: 8px;
            }
            table > thead > tr > th {
              background-color: ${gstyles.colors.background2};
            }
          }
        }
      }
      .ant-table-pagination {
        align-items: center;
        .ant-pagination-options-size-changer {
          .ant-select-selector {
            height: 32px;
            .ant-select-selection-item {
              line-height: 32px;
            }
          }
        }
        &.ant-table-pagination-right {
          margin-right: 24px;
        }
      }
    }
  }
`;

const CustomTable = (props) => {
  const computedProps = { ...props };
  _.update(computedProps, 'pagination', (pagination) => {
    pagination = pagination || {};
    return {
      hideOnSinglePage: true,
      showSizeChanger: true,
      ...pagination,
    };
  });
  return (
    <DIV>
      <StyledTable
        locale={{ emptyText: <div className="py-6">{ctx.apply('i18n.t', 'noData')}</div> }}
        {...computedProps}
      />
    </DIV>
  );
};

export default displayName(CustomTable);
